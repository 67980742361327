import React, { useState, useEffect } from "react"
//import Img from "gatsby-image"
import { isAuthenticated, getProfile } from "../utils/auth"
import Link from "gatsby-link"

//import Header from './header'
import Thumb from './thumb-fixed-img'

import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    connectHits,
    SearchBox,
    Pagination,
    //Highlight,
    //ClearRefinements,
    //CurrentRefinements,
    RefinementList,
    Configure,
    //ScrollTo,
    //HitsPerPage
} from 'react-instantsearch-dom';

const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const Hits = connectHits(({ hits }) => {

    const [favorites, setFavorites] = useState([]);
    //const getArray = JSON.parse(localStorage.getItem('favorites') || '0');
    //const getArray = typeof window !== 'undefined' && window.localStorage.getItem('favorites') ? JSON.parse(window.localStorage.getItem('favorites') || '0') : {};
    
    useEffect(() => {
        const getArray = JSON.parse(localStorage.getItem('favorites') || '0');
        if( getArray !== 0 ){
            setFavorites([...getArray]);
        }
    }, []);

    const addFav = (props) => {
        let array = favorites;
        let addArray = true;
        //console.log(props);

        array.map((item, key) => {
            if (item === props.objectID) {
                array.splice(key, 1);
                addArray = false;
            }
        });

        if (addArray) {
            array.push(props.objectID);
        }
        setFavorites([...array]);
        window.localStorage.setItem('favorites', JSON.stringify(favorites));
    
        var storage = window.localStorage.getItem('favItem' + (props.objectID) || 0);
        if( storage == null ) {
            window.localStorage.setItem(('favItem' + (props.objectID)), JSON.stringify(props));
        }

    };

    return(
    <div className="items">
        {/* Always use a ternary when coercing an array length */}
        {/* otherwise you might print out "0" to your UI */}
        {hits.length ? (
            <>
                {hits.map(hit => {
                return (
                    <div className="item card" key={hit.objectID}>

                        <Link to={'/products/' + hit.slug} className={"link"}>
                            
                            <div className="image">

                                {hit.acf.product__image
                                    ?
                                    <img
                                        src={hit.acf.product__image.localFile.childImageSharp.fixed.src}
                                        alt={hit.title}
                                    />
                                    :
                                    <Thumb />
                                }

                            </div>

                            <div className={"content"}>

                                <h3 className="title">{hit.title}</h3>
                                <h4 className="type">
                                    {/* {hit.type.map(type => type.name)} */}
                                    {hit.type.map((type) => (type.name)).join(', ')}
                                </h4>
                                
                            </div>

                        </Link>

                        <div className="favorite">
                            {favorites.includes(hit.objectID) ? (
                                <button className="is-favorite" onClick={() => addFav(hit)}>
                                    <span className="label">Add to</span>
                                    <i className="gg-heart"></i>
                                </button>
                            ) : (
                                <button onClick={() => addFav(hit)}>
                                    <span className="label">Remove to</span>
                                    <i className="gg-heart"></i>
                                </button>
                            )}
                        </div>

                    </div>
                )
                })}
            </>
        ) : (
            <p>There were no results for your query. Please try again.</p>
        )}
    </div>
    )
})

const Search = () => {

    const user = getProfile();
    const ruolo = user['https://app.company.com/ruolo_app'];
    
    return (
        <InstantSearch
            indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
            searchClient={searchClient}
        >
            <Configure
                hitsPerPage={12}
                filters={`viewable:${ruolo}`}
            />

            <div className="search container">

                <div className="filters">
                    
                    <div className="items">
                        <div className="item">
                            <h2 className="title">Prodotto</h2>
                            <SearchBox translations={{
                                placeholder: 'Cerca codice',
                            }}
                            />
                        </div>
                        <div className="item">
                            <h2 className="title">Categorie</h2>
                            <RefinementList limit={99} attribute="type.name" />
                        </div>
                        <div className="item">
                            <h2 className="title">Materiali</h2>
                            <RefinementList limit={99} attribute="material.name" />
                        </div>
                        <div className="item">
                            <h2 className="title">Stile</h2>
                            <RefinementList limit={99} attribute="style.name" />
                        </div>
                        <div className="item">
                            <h2 className="title">Fori</h2>
                            <RefinementList limit={99} attribute="hole.name" />
                        </div>
                        <div className="item">
                            <h2 className="title">Stagione</h2>
                            <RefinementList limit={99} attribute="season.name" />
                        </div>
                        <div className="item">
                            <h2 className="title">Genere</h2>
                            <RefinementList limit={99} attribute="genre.name" />
                        </div>
                    </div>
                    
                    {/* <ClearRefinements
                        translations={{reset: 'Pulisci filtri'}}
                    /> */}
                    
                </div>

                <div className="results products">
                    
                    {/* <ScrollTo> */}

                        <Hits />

                        <Pagination />

                        {/*<HitsPerPage
                            defaultRefinement={5}
                            items={[
                                { value: 5, label: 'Show 5 hits' },
                                { value: 10, label: 'Show 10 hits' },
                            ]}
                        />*/}

                    {/* </ScrollTo> */}

                </div>

            </div>
            
            
        </InstantSearch>
    )
}

export default Search;