import React, { Component } from "react"
/*import { graphql } from "gatsby"
import Link from "gatsby-link"
import Img from "gatsby-image"
import Thumb from '../../components/thumb-fixed'
import PropTypes from "prop-types"*/

//import { Router } from "@reach/router"
import { login, isAuthenticated } from "../../utils/auth"

import Layout from "../../components/layout"
import Search from '../../components/search'


class ProductsTemplate extends Component {

    render() {

        if( !isAuthenticated() ) {
            login()
            return(
                <Layout>
                    <div className="container"><p>Redirecting to login...</p></div>
                </Layout>
            )
        }

        return(
            <Layout>
                
                <Search />

            </Layout>
        )
    }
}
/*
ProductsTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}
*/
export default ProductsTemplate
/*
export const ProductsTemplateQuery = graphql`
    query {
        allWordpressWpProduct{
            edges{
                node{
                    id
                    title
                    slug
                    acf {
                        product__image {
                            localFile {
                                childImageSharp {
                                    fixed {
                                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                    }
                                    fluid {
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    type {
                        name
                    }
                }
            }
        }
    }
`*/